import ReactDOM from 'react-dom'
import React from 'react'
import './styles.css'
import App from './App'

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      {/* <a href="https://superexcellent.com.au/" style={{ position: 'absolute', bottom: 40, left: 40, fontSize: '72px', fontWeight: 'semibold' }}>
        SuperExcellent
      </a> */}
    </div>
  )
}

ReactDOM.render(
  <>
    <App />
    <Overlay />
  </>,
  document.getElementById('root')
)
