/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import { useState, useMemo } from 'react'
import { useThree } from '@react-three/fiber'
import { useGLTF, Float } from '@react-three/drei'
import { LayerMaterial, Base, Depth, Fresnel, Noise } from 'lamina/vanilla'

const colorA = new THREE.Color('#860036').convertSRGBToLinear()
const colorB = new THREE.Color('#481462').convertSRGBToLinear()
const fresnel = new THREE.Color('#e773bb').convertSRGBToLinear()
const material = new LayerMaterial({
  layers: [
    new Base({ color: colorA }),
    new Depth({ colorA: colorA, colorB: colorB, alpha: 0.2, mode: 'normal', near: 0, far: 2, origin: [1, 1, 1] }),
    new Depth({ colorA: '#66003b', colorB: colorB, alpha: 0.5, mode: 'add', near: 3, far: 2, origin: [1, 1, 1] }),
    new Fresnel({ mode: 'add', color: fresnel, intensity: 0.2, power: 2.5, bias: 0.0 }),
    new Noise({ mapping: 'local', type: 'simplex', scale: 1000, colorA: '#ffa600', colorB: '#ff396e', mode: 'overlay' })
  ]
})

function ICream() {
  const { viewport, camera } = useThree()
  const { nodes } = useGLTF("/soft_serve_cone.gltf")
  const [geometry] = useState(() => nodes[`ice_cream_base`, 'ice_cream_melted'].geometry)
  const [speed] = useState(() => 0.2 + Math.random() / 10)
  const position = useMemo(() => {
    const z = Math.random() * -30
    const bounds = viewport.getCurrentViewport(camera, [0, 0, z])
    return [THREE.MathUtils.randFloatSpread(bounds.width), THREE.MathUtils.randFloatSpread(bounds.height * 0.75), z]
  }, [viewport])
  return (
    <Float position={position} speed={speed} rotationIntensity={10} floatIntensity={50} dispose={null}>
      <group scale={.65} geometry={geometry} material={material}>
      <mesh
      scale={1}
        castShadow
        receiveShadow
        geometry={nodes.ice_cream_base.geometry}
        material={material}
      />
      <mesh
      scale={1}
        castShadow
        receiveShadow
        geometry={nodes.ice_cream_melted.geometry}
        material={material}
      />
      <mesh
      scale={1}
        castShadow
        receiveShadow
        geometry={nodes.waffle_cone.geometry}
        material={material}
      />
    </group>
    </Float>
  )
}

export default function IceCream() {
  return Array.from({ length: 16 }, (_, i) => <ICream key={i} />)
}

useGLTF.preload("/soft_serve_cone.gltf");